.glide {
  max-width: 120rem;
  margin: auto;
  position: relative;

  &__track {
    height: 35rem;
  }

  &__shadow {
    position: absolute;

    height: 35rem;
    width: 5rem;
    z-index: 1;

    &--left {
      top: 0;
      left: 0;
      background: linear-gradient(
        to right,
        $intro-color,
        rgba($intro-color, 0)
      );
    }
    &--right {
      top: 0;
      right: 0;
      background: linear-gradient(to left, $intro-color, rgba($intro-color, 0));
    }
  }

  &__slides {
    height: 30rem;
    display: flex;
    align-items: center;
  }
  &__slide {
    height: 60%;
    transition: all 300ms ease;

    &--active {
      transform: scale(1.4);
    }

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__bg-img {
    width: 100%;
    height: 90%;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &--html {
      background-image: url("../../images/slider/html.png");
    }

    &--js {
      background-image: url("../../images/slider/js.png");
    }

    &--css {
      background-image: url("../../images/slider/css.png");
    }

    &--react {
      background-image: url("../../images/slider/react.png");
    }

    &--sass {
      background-image: url("../../images/slider/sass.png");
    }

    &--gatsby {
      background-image: url("../../images/slider/gatsby.png");
    }

    &--webpack {
      background-image: url("../../images/slider/webpack.png");
    }
    &--photoshop {
      background-image: url("../../images/slider/photoshop.png");
    }
  }

  &__bg-title {
    margin: 0;
  }

  &__arrow {
    color: $header-blue;
    border: 2px solid rgba($header-blue, 0.3);
    background-color: rgba($color-grey-light-1, 0.55);

    &--left {
      left: -2rem;
      @include respond(phone) {
        left: -1rem;
      }
    }

    &--right {
      right: -2rem;
      @include respond(phone) {
        right: -1rem;
      }
    }
  }
}
