$page-margin: 10rem;
$page-margin-lg: 30rem;
$page-margin-mobile: 3rem;

// Colors
$color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

$color-blue-light: #2d71c3;
$color-blue-dark: #00147e;
$color-gold: #e4c16e;
$color-purple: #6870d3;

$header-blue: #5e96eb;

$background-color-1: #6aadff;
$background-image-1: url("https://www.transparenttextures.com/patterns/diagmonds-light.png");

$background-color-2: #e6c979;
$background-image-2: url("https://www.transparenttextures.com/patterns/dark-stripes-light.png");

// Page colors
$intro-color: $color-grey-light-1;
