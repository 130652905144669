.u-center-text {
  text-align: center !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-l {
  margin-left: 2rem !important;
}

.hidden {
  display: none;
}
