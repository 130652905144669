@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap");

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  // font-size: 10px;
  line-height: 1.7;
  color: $color-grey-dark-3;
}

h1 {
  font-size: 3rem;
  margin: 1rem 0;
}

h2 {
  font-size: 2.6rem;
  margin: 1rem 0;
}

// h2 styled
.styled-headline {
  font-size: 4rem;
  text-align: center;
  color: $color-primary;
  text-decoration: underline;
}

h3 {
  font-size: 2.2rem;
  margin: 1rem 0;
}
h4 {
  font-size: 1.9rem;
}

p {
  font-size: 1.7rem;
  margin-bottom: 1rem;
}

$p-spacing: 1rem;

a,
a:visited {
  color: $header-blue;
}

a:hover,
a:active {
  color: $color-primary;
}

ul > li {
  font-size: 1.7rem;
}

ul.primary-list {
  list-style: none;
  font-size: 1.7rem;
}

li.primary-list__item {
}
