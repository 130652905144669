#menu li.active a {
  color: $header-blue;
  border-color: $header-blue;
}

.navigation {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.8);
  position: sticky;
  top: 0;
  height: 4vh;
  z-index: 1000;

  &__nav {
    height: 100%;
  }

  &__list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 80rem;
    margin: auto;
    list-style: none;
    height: 100%;
  }

  &__item {
    font-size: 2rem;
    // padding: 0 1rem;
  }

  &__link {
    transition: all 0.2s;

    &:link,
    &:visited {
      font-size: 2rem;
      font-weight: bold;
      padding: 0 1rem;
      color: $color-primary;
      text-decoration: none;
      text-transform: uppercase;

      &.active,
      &:hover,
      &:active {
        color: $header-blue;
        border-color: $header-blue;
      }
    }

    &--hire {
      // border: 2px solid $color-primary;
      // border-radius: 5px;
      // font-size: 1.8rem !important;

      // &:hover {
      //   border: 2px solid $header-blue;
      // }
    }
  }
}
