.contactCard {
  text-align: center;
  margin-bottom: 2rem;
  & h3 {
    color: $color-primary;
    font-size: 3.5rem;
    margin: 0;
    text-shadow: 3px 3px 2px rgba(black, 0.2);
  }
}

.contactForm {
  width: 100%;
  background-color: rgba($color-white, 0.85);
  padding: 5rem;
  padding-top: 3rem;
  border-radius: 10px;
  border: 2px solid rgb(28, 28, 28);
  box-shadow: 7px 10px 15px rgba($color: #000000, $alpha: 0.5);

  // Contact info at top of card
  &__contact {
    &__list {
      padding-bottom: 3rem;
      list-style: none;
      display: flex;
      justify-content: space-between;
    }

    &__item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-right: 3rem;
      }

      &:hover > * {
        color: $color-primary-dark;
      }

      & a {
        color: #333;
        line-height: 3.5rem;
      }
    }

    &__icon-container {
      margin-right: 1rem;
      height: 3.5rem;
      display: flex;
      align-items: center;
    }

    &__icon {
      font-size: 2.5rem;
    }
  }

  // Form styling
  &__title {
    text-align: center;
    margin-bottom: 1rem;

    & > * {
      display: inline-block;
    }

    & h3 {
      margin-right: 1rem;
    }
  }

  &__statusMessage {
    text-align: center;
    font-size: 2rem;
    display: none;

    &.success {
      color: green;
      display: none;
    }

    &.fail {
      color: red;
      display: none;
    }
  }
}

.form {
  &__grid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem 3rem;
    margin-bottom: 1rem;

    @include respond(tab-land) {
      grid-template-columns: auto;
      gap: 1.5rem;
    }

    & > div {
      height: 22rem;
    }
  }

  &__label {
    font-size: 1.6rem;
    font-weight: 700;
    display: block;
  }

  &__input {
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    padding: 1rem 2rem;
    border-radius: 2px;
    background-color: rgba($color-white, 0.7);
    border: none;
    border-bottom: 3px solid transparent;
    width: 100%;
    display: block;
    transition: all 0.3s;

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, $alpha: 0.1);
      border-bottom: 3px solid $color-primary;
    }

    &[type="textarea"] {
      font-size: 1.6rem;
      height: 17.7rem;
      padding: 1.5rem 2rem;
    }
  }

  &__button {
    margin: 2rem auto 0 auto;
    text-align: center;
    font-size: 2.1rem;
    font-weight: 700;
    display: block;
    padding: 0 3rem;
    border-radius: 10px;
    background-image: linear-gradient(
      to bottom right,
      $color-blue-light,
      $color-blue-dark
    );
    color: $color-white;
    transition-duration: 0.2s;

    &:hover {
      transform: scale(1.05) translateY(-3px);
      box-shadow: 0 1rem 2rem rgba($color-black, $alpha: 0.1);
    }
  }
}
