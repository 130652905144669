$innerDivPadding: 1rem;

.tracker {
  margin: 3rem 0;
  padding: 2rem;
  min-height: 20rem;
  border: 1px solid black;
  background-color: rgb(85, 85, 85);
  border-radius: 10px;

  color: rgb(199, 199, 199);

  ul {
    list-style: none;
  }
}
