// Media query manager
/*
0 - 600px:      phone
600 - 900 px:   tablet portrait
900 - 1200 px:  tablet landscape
[1200 - 1800]px is where are normal styles apply
1800px + :      big desktop


$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px
*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } // 600px
  }

  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    } // 900px
  }

  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    } // 1200px
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } // 1800px
  }
}
