.project {
  h3 {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 3.5rem;
  }

  p {
    margin-bottom: $p-spacing;
  }
}
